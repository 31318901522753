// Similar to Stimilus OutletObserver calling controller#connect

const processAttributeChange = function(mutation) {
  if ($(mutation.target).filter(":hidden").length > 0) return;
  const targets = [
    ...(mutation.target.hasAttribute("data-onvisible") ? [mutation.target] : []),
    ...mutation.target.querySelectorAll("[data-onvisible]")
  ];

  $(targets).each(function(idx, target) {
    eval(target.dataset.onvisible);
  });
};

const processAddedNodes = function(mutation) {
  mutation.addedNodes.forEach(function(node) {
    if (node.nodeType != Node.ELEMENT_NODE) return;
    let targets = [
      ...(node.hasAttribute("data-onready") ? [node] : []),
      ...node.querySelectorAll("[data-onready]")
    ]

    $(targets).each(function(idx, target) {
      eval(target.dataset.onready);
    });
  });
};

const elementObserver = new MutationObserver(function(entries) {
  entries.forEach(function(entry) {
    if (entry.type == "attributes") {
      processAttributeChange(entry);
    } else if (entry.addedNodes.length > 0) {
      processAddedNodes(entry);
    }
  });
});

elementObserver.observe(
  document,
  { childList: true, subtree: true,
    attributes: true, attributeFilter: ["style", "class"] }
)
